import React from 'react';
import Layout from "../components/Layout";
import {defineMessages, useIntl} from "react-intl";
import timeBoard from "../assets/images/time-manage/time-management.svg";
import arrowBottom from "../assets/images/time-manage/arrow-top.svg";
import arrowTop from "../assets/images/time-manage/arrow-bottom.svg";
import worklogReport from "../assets/images/time-manage/worklog-report.svg"
import worklogOverview from "../assets/images/time-manage/report-overview.svg";
import manBed from "../assets/images/time-manage/man-bed.svg";
import icons from "../assets/images/time-manage/icons.svg";
import teamCal from "../assets/images/time-manage/team-calendar.svg";
import line1 from "../assets/images/time-manage/line-advice1.svg";
import line2 from "../assets/images/time-manage/line-advice2.svg";
import line3 from "../assets/images/time-manage/line-advice3.svg";
import line4 from "../assets/images/time-manage/line-advice4.svg";
import lineHeader1 from "../assets/images/time-manage/header_line.svg";
import line from "../assets/images/line2.svg";
import dayOffBoard from "../assets/images/time-manage/dayoff-board.svg";
import dayOffBoardMobile from "../assets/images/time-manage/dayoff-board2.svg";
import list from "../assets/images/time-manage/list.svg";
import alarmTicket from "../assets/images/time-manage/alarm-ticket.svg";
import longLine from "../assets/images/long-line.svg";
import longLine2 from "../assets/images/long-line2.svg";
import Img from "../components/Img";

const intro = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Time Management'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Progress and activity'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Employees have a preview on their team mates availability and team managers have a good view on your employees progress and activity.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Define process'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship allows you to organize your team daily activities. \n' + 'Let your employees to define their working hours, preview and correct timelogs and be able to request and manage their leaves.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'time board - Scrumship'
    },
});

const worklog = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Worklog Report'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Worklog report enables you to see the logged-in hours which your employees have allocated to their work. The feature also allows you to see how much time users dedicated to work during the week and whole month. Moreover,  you can see if a user took any days off or if there are any holidays coming.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Detailed overview'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'If you click on worklog you will see statistics and more info on tasks.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'Day off'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'You can see sick leaves, vacations or bank holidays.'
    },
    advice3Header: {
        id: 'Header.Text',
        defaultMessage: 'Summary'
    },
    advice3Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship automaticaly sums up daily weekly and monthly working hours.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'worklog report in Scrumship time management software'
    },
    board2: {
        id: 'Alt.Text',
        defaultMessage: 'worklog report overview in Scrumship time management app'
    }
});

const teamCalendar = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'My Team Calendar'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Scrumship allows you to organize your team daily activities.'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship allows you to organize your team daily activities.\n' + 'Let your employees to define their working hours, preview and correct their timelogs, be able to request and manage their leaves. It also allows the employees to have a preview on their team mates availability, and allow your team managers to have a good view on your employees progress.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Current time'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'You see the current time.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'Activity log'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'You can indicate whether your developers are actively working on the code or not.'
    },
    advice3Header: {
        id: 'Header.Text',
        defaultMessage: 'Visible days off'
    },
    advice3Text: {
        id: 'Content.Text',
        defaultMessage: 'You see when your teammates are sick or on vacations.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'team calendar in Scrumship time management app'
    },
});

const dayOff = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Day Off Management'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Request a day off'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'In Scrumship every employee can request a day off, a vacation or a sick leave.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Get notified'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Once requested, Scrumship will notify a manager to accept or decline the leave (on email or Slack). Employee will also get notified once the leave is accepted or declined.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Accepted day off'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'If accepted, the request turns green and notifies the employee. If it’s a sick leave you can also easily indicate that based on the icon.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'Day off acceptance'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'Manager can easily reject or approve the request.'
    },
    advice3Header: {
        id: 'Header.Text',
        defaultMessage: 'Rejected Day Off'
    },
    advice3Text: {
        id: 'Content.Text',
        defaultMessage: 'If rejected the request turns red and the employee gets notified.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'day off management board in Scrumship'
    },
    list: {
        id: 'Alt.Text',
        defaultMessage: 'list of tasks'
    },
    ticket: {
        id: 'Alt.Text',
        defaultMessage: 'ticket with red alarm'
    }
});

const globalAlts = defineMessages({
    junior: {
        id: 'Alt.Text',
        defaultMessage: 'junior developer'
    },
    icons: {
        id: 'Alt.Text',
        defaultMessage: 'worklog report icons'
    }
});

const TimeManagement = ({location}) => {
    const {formatMessage} = useIntl();
    return (
        <Layout pageName='time-management-subpage' location={location}>
            <section className='intro'>
                <div className='container'>
                    <div className='section-one'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(intro.header)}</h1>
                        </div>
                        <div className='time-board'>
                            <div className='image-container full-width'>
                                <Img src={timeBoard} alt={formatMessage(intro.board)}/>
                            </div>
                        </div>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <div className='title title-no-line'><h3>{formatMessage(intro.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(intro.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container full-width text-left'>
                                <div className='title title-no-line'><h3>{formatMessage(intro.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(intro.protip2Text)}</p>
                            </div>
                        </div>
                        <div className='arrow-top'>
                            <Img src={arrowTop} alt=""/>
                        </div>
                        <div className='arrow-bottom'>
                            <Img src={arrowBottom} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <section className='worklog-report'>
                <div className='container'>
                    <div className='section-two'>
                        <div className='man'>
                            <div className='image-container full-width'>
                                <Img src={manBed} alt={formatMessage(globalAlts.junior)}/>
                            </div>
                        </div>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(worklog.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <p className='protip-black'>{formatMessage(worklog.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='worklog'>
                            <div className='image-container full-width'>
                                <Img src={worklogReport} alt={formatMessage(worklog.board)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper1'>
                            <div className='advice-container advice1'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(worklog.advice1Header)}</h4>
                                    <p>{formatMessage(worklog.advice1Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice1'>
                                <Img src={line} alt=""/>
                            </div>
                        </div>
                        <div className='overview'>
                            <div className='image-container full-width'>
                                <Img src={worklogOverview} alt={formatMessage(worklog.board2)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper2'>
                            <div className='advice-container advice2'>
                                <div className='advice-body'>
                                    <div>
                                        <h4>{formatMessage(worklog.advice2Header)}</h4>
                                        <Img src={icons} alt={formatMessage(globalAlts.icons)}/>
                                    </div>
                                    <p>{formatMessage(worklog.advice2Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice2'>
                                <Img src={line} alt={formatMessage(globalAlts.junior)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper3'>
                            <div className='advice-container advice3'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(worklog.advice3Header)}</h4>
                                    <p>{formatMessage(worklog.advice3Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice3'>
                                <Img src={line} alt={formatMessage(globalAlts.junior)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='team-calendar'>
                <div className='container'>
                    <div className='section-three'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(teamCalendar.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <div className='title title-no-line'>
                                    <h3>{formatMessage(teamCalendar.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(teamCalendar.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='calendar'>
                            <div className='image-container full-width'>
                                <Img src={teamCal} alt={formatMessage(teamCalendar.board)}/>
                            </div>
                        </div>
                        <div className='advice-container advice1'>
                            <div className='advice-body'>
                                <h4>{formatMessage(teamCalendar.advice1Header)}</h4>
                                <p>{formatMessage(teamCalendar.advice1Text)}</p>
                            </div>
                        </div>
                        <div className='advice-container advice2'>
                            <div className='advice-body'>
                                <h4>{formatMessage(teamCalendar.advice2Header)}</h4>
                                <p>{formatMessage(teamCalendar.advice2Text)}</p>
                            </div>
                        </div>
                        <div className='advice-container advice3'>
                            <div className='advice-body'>
                                <h4>{formatMessage(teamCalendar.advice3Header)}</h4>
                                <p>{formatMessage(teamCalendar.advice3Text)}</p>
                            </div>
                        </div>
                        <div className='line-advice1'>
                            <Img src={line1} alt=""/>
                        </div>
                        <div className='line-advice2'>
                            <Img src={line2} alt=""/>
                        </div>
                        <div className='line-advice2b'>
                            <Img src={longLine} alt={formatMessage(globalAlts.junior)}/>
                        </div>
                        <div className='line-advice3'>
                            <Img src={line3} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <section className='day-off'>
                <div className='container'>
                    <div className='section-four'>
                        <div className='label'>
                            <div className='line-header1'>
                                <img src={lineHeader1} alt=""/>
                            </div>
                            <h1 className='text-decoration-label'>{formatMessage(dayOff.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(dayOff.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(dayOff.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='dayoff-board desktop'>
                            <div className='image-container full-width'>
                                <Img src={dayOffBoard} alt={formatMessage(dayOff.board)}/>
                            </div>
                        </div>
                        <div className='dayoff-board mobile'>
                            <div className='image-container full-width'>
                                <Img src={dayOffBoardMobile} alt={formatMessage(dayOff.board)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(dayOff.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(dayOff.protip2Text)}</p>
                            </div>
                        </div>
                        <div className='advice-container advice1'>
                            <div className='advice-body'>
                                <h4>{formatMessage(dayOff.advice1Header)}</h4>
                                <p>{formatMessage(dayOff.advice1Text)}</p>
                            </div>
                        </div>
                        <div className='advice-container advice2'>
                            <div className='advice-body'>
                                <h4>{formatMessage(dayOff.advice2Header)}</h4>
                                <p>{formatMessage(dayOff.advice2Text)}</p>
                            </div>
                        </div>
                        <div className='advice-container advice3'>
                            <div className='advice-body'>
                                <h4>{formatMessage(dayOff.advice3Header)}</h4>
                                <p>{formatMessage(dayOff.advice3Text)}</p>
                            </div>
                        </div>
                        <div className='line-advice1'>
                            <Img src={longLine} alt=""/>
                        </div>
                        <div className='line-advice2'>
                            <Img src={longLine2} alt=""/>
                        </div>
                        <div className='line-advice2b'>
                            <Img src={longLine} alt=""/>
                        </div>
                        <div className='line-advice3'>
                            <Img src={line4} alt=""/>
                        </div>
                        <div className='line-advice3b'>
                            <Img src={longLine} alt=""/>
                        </div>
                        <div className='list'>
                            <div className='image-container full-width'>
                                <Img src={list} alt={formatMessage(dayOff.list)}/>
                            </div>
                        </div>
                        <div className='alarm-ticket'>
                            <div className='image-container full-width'>
                                <Img src={alarmTicket} alt={formatMessage(dayOff.ticket)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default TimeManagement;
